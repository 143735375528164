.navbar{
  background-color: grey;
  height: 50px;
  min-width: 100%;
} 

.navbar-header{
  color: white;
  font-weight: 800;
  font-stretch: expanded;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding-top: 8px;
  margin-left: 28rem;
}

.add-result-table{
  display: flex;
  max-width: 2xl;
  height: 500px;
  box-shadow: 10px;
  border:black 1px solid;
  margin: 3rem 5rem;
}

.result-table-row{
  padding: 8px 8px;
  font-weight: lighter;
  -webkit-text-decoration: wavy;
          text-decoration: wavy;
  font-size: large;
  margin-left: 13rem;
}

.add-result-table-header{
  padding-bottom: 5px;
  margin-left: -2rem;
}

.result-data{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 14px;
  width:100px;
  margin-top: 6rem;
  margin-left: 5rem;

}
.label{
  font-size: small;
  font-weight: normal;  
}

.input{
  border: black solid .035px;
  margin-left: 26px;
  width: 260px;
  padding-left: 6px;
  padding-top: 2px;
  font-weight: 400;
}
.button{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
  margin-left: -8rem;
}
.button-save{
  background-color: green;
  padding: 3px 3px;
  color: white;
  font-weight: 400;
  border-radius: 5px;
  width: 65px;
}

.button-clean{
  background-color:red;
  padding: 3px 3px;
  color: white;
  font-weight: 400;
  border-radius: 5px;
  width: 65px;
  margin-left: 1rem;
}

.button-cancel{
  background-color:rgb(15, 15, 15);
  padding: 3px 3px;
  color: white;
  font-weight: 400;
  border-radius: 5px;
  width: 65px;
  margin-left: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  margin-top: 1rem;
}

.add-btn-list{
  font-display: flex;
  margin: auto;
}
.button-addlist{
  border-radius: 5px;
  background-color: slateblue;
  color: white;
  padding-top: 6px;
  padding-right: 2px;
  margin-left: -25rem;
  margin-bottom: 0.25rem;
}

.thead{
  background-color: grey;
  color: white;
}

.table-row{
  border: solid black;
  -webkit-columns: red ;
          columns: red ;
}
 .table-header-todo{
 align-content: flex-end;
  color: brown;
  background-color: aqua;
}

.todo-data{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.span-edit {
  background-color: green;
  padding: 3px 3px;
  color: white;
  font-weight: 400;
  border-radius: 5px;
  width: 45px;
  margin-left: 1rem;
  cursor: pointer;
}

.span-delete {
  background-color:red;
  padding: 3px 3px;
  color: white;
  font-weight: 400;
  border-radius: 5px;
  width: 45px;
  margin-left: 1rem;
  cursor: pointer;
}

#card-body{
 
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content:space-around;
  align-items: center;
  width: auto;
  height: auto;
  
}

h2{
  font-size: 24px;
  font-stretch: semi-expanded;
  align-content: center;
  margin-top: 5rem;
}

#email{
  margin-top: 0.5rem;

}

.form-label-email{
  padding-right: 52px;
  margin-bottom: 1.5rem;

}
.form-label-password{
  padding-right: 21px;

}
.signin-btn{

  margin-top: 0.25rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#email{
  display: flex;
  margin-top: 0.5rem;

}

.form-label-email{
  padding-right: 19px;
  margin-bottom: 1.5rem;
}
.form-label-password{
  padding-right: 30px;


 } 
 .signin-btn{
  background-color: grey;
  color: white;
  font-weight: 600;
  font-size: medium;
  margin-top: 0.25rem;
  width: 100px;
  height: 30px;
  margin-left: 5rem;
  margin-top: 2rem;
} 

.logout-btn{
  background-color: grey;
  color: white;
  font-weight: 500;
  font-size: medium;
  padding-right: 10px;
  cursor: pointer;
  width: 95px;
  height: 28px;
  margin-left: 6rem;
  margin-top: -9rem;
}




@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

